import React, { memo } from "react";

import { Select } from "components/Input/Select";
import { convertStringToBooleanOrUndefined } from "hooks/useFilterConditions";

type Props = {
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
};

export const IsVisibleForKioskField = memo<Props>(({ value, onChange }) => (
  <Select<string>
    showSearch
    allowClear
    defaultValue="undefined"
    value={String(value)}
    onChange={(isDisplay) => onChange(convertStringToBooleanOrUndefined(isDisplay))}
    optionFilterProp="label"
    options={[
      { label: "キオスクの公開設定", value: "undefined" },
      { label: "キオスクに公開", value: "true" },
      { label: "キオスクに非公開", value: "false" },
    ]}
    data-cy="select-is-visible-for-kiosk"
  />
));
