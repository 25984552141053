import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Col, Row } from "antd";
import { filterMenusByName } from "models/menu";
import * as stock from "models/stock";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { ShopSelector } from "components/ShopSelector";
import { useCompany } from "hooks/useCompany";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { usePagination } from "hooks/usePagination";
import { useShop } from "hooks/useShop";
import {
  useShopMenusGetCategoriesQuery,
  useShopMenusGetDishUpSlipGroupsQuery,
  useShopMenusGetKdDisplayTargetsQuery,
  useShopMenusGetMenusQuery,
  useShopMenusGetRolesQuery,
  useShopMenusUpdateShopMenusMutation,
  useShopMenusUpdateShopMenuStockMutation,
  useShopMenusUpdateShopMenuVisibleForCustomerMutation,
  useShopMenusUpdateShopMenuVisibleForKioskMutation,
  useShopMenusUpdateShopMenuVisibleForStaffMutation,
  useShopMenusUpsertShopChoicesMutation,
} from "pages/ShopMenus/queries";
import { FilterConditions, ShopMenuFilter } from "pages/ShopMenus/ShopMenuFilter";
import { ShopMenuTable } from "pages/ShopMenus/ShopMenuTable";
import { Menu } from "pages/ShopMenus/types";
import { CategoryMenu, UpdateShopMenusInput, UpsertShopChoicesInput } from "types/graphql";

const StyledButton = styled(Button)`
  color: #4096ff;
  border-color: #4096ff;
`;

const isIncludedInCategoryIds = (
  menu: { categoryMenus: Array<{ categoryId: CategoryMenu["_categoryId"] }> },
  categoryIds: number[],
) => {
  const menuCategoryIds = menu.categoryMenus.map(({ categoryId }) => categoryId);
  return categoryIds.some((categoryId) => menuCategoryIds.includes(categoryId));
};

const isIncludedInRoleIds = (menu: Menu, roleIds: number[]) => {
  const menuRoleIds = menu.shopMenuKitchenRoles.map(({ role }) => role?.roleId);
  return roleIds.some((categoryId) => menuRoleIds.includes(categoryId));
};

const isIncludedInDishUpSlipGroupIds = (menu: Menu, dishUpSlipGroupIds: number[]) => {
  const menuDishUpSlipGroupIds = menu.dishUpSlipGroupShopMenus.map(
    ({ dishUpSlipGroup }) => dishUpSlipGroup?.id,
  );
  return dishUpSlipGroupIds.some((categoryId) => menuDishUpSlipGroupIds.includes(categoryId));
};

const isIncludedInKdDisplayTargetIds = (menu: Menu, kdDisplayTargetIds: string[]) => {
  const menuKdDisplayTargetIds = menu.shopMenuKdDisplayTargets.map(
    ({ kdDisplayTarget }) => kdDisplayTarget?.id,
  );

  return kdDisplayTargetIds.some((kdDisplayTargetId) =>
    menuKdDisplayTargetIds.includes(kdDisplayTargetId),
  );
};

const filterMenus = (
  menus: Menu[],
  {
    name,
    categoryIds,
    isVisibleForCustomer,
    isVisibleForKiosk,
    isVisibleForStaff,
    isSoldOut,
    ...filterConditions
  }: FilterConditions,
) => {
  const roleIds = filterConditions.roleIds !== "empty" ? filterConditions.roleIds : undefined;
  const dishUpSlipGroupIds =
    filterConditions.dishUpSlipGroupIds !== "empty"
      ? filterConditions.dishUpSlipGroupIds
      : undefined;
  const kdDisplayTargetIds =
    filterConditions.kdDisplayTargetIds !== "empty"
      ? filterConditions.kdDisplayTargetIds
      : undefined;

  const filteredMenus = menus.filter(
    (menu) =>
      (categoryIds === undefined || isIncludedInCategoryIds(menu, categoryIds)) &&
      (isVisibleForCustomer === undefined || menu.isVisibleForCustomer === isVisibleForCustomer) &&
      (isVisibleForKiosk === undefined || menu.isVisibleForKiosk === isVisibleForKiosk) &&
      (isVisibleForStaff === undefined || menu.isVisibleForStaff === isVisibleForStaff) &&
      (isSoldOut === undefined || stock.isSoldOut({ stock: menu.stock }) === isSoldOut) &&
      (roleIds === undefined || isIncludedInRoleIds(menu, roleIds)) &&
      (filterConditions.roleIds !== "empty" || menu.shopMenuKitchenRoles.length === 0) &&
      (dishUpSlipGroupIds === undefined ||
        isIncludedInDishUpSlipGroupIds(menu, dishUpSlipGroupIds)) &&
      (filterConditions.dishUpSlipGroupIds !== "empty" ||
        menu.dishUpSlipGroupShopMenus.length === 0) &&
      (kdDisplayTargetIds === undefined ||
        isIncludedInKdDisplayTargetIds(menu, kdDisplayTargetIds)) &&
      (filterConditions.kdDisplayTargetIds !== "empty" ||
        menu.shopMenuKdDisplayTargets.length === 0),
  );

  return name ? filterMenusByName(filteredMenus, name) : filteredMenus;
};

export const ShopMenus = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const [defaultPagination, setPagination] = usePagination();

  const [company] = useCompany();
  const [currentShop] = useShop();

  const companyId = company?.id;
  const shopId = currentShop?.shopId;

  const {
    data: getMenusData,
    loading: loadingMenus,
    error: getMenusDataError,
    refetch: refetchShopMenus,
  } = useShopMenusGetMenusQuery(shopId ? { variables: { shopId } } : { skip: true });
  const shop = useMemo(() => getMenusData?.shop_by_pk, [getMenusData]);
  const shopMenus = useMemo(() => shop?.shopMenus ?? [], [shop]);
  const menus = useMemo(
    () =>
      shopMenus
        .map(({ menu, ...shopMenu }) => ({ ...shopMenu, ...menu }))
        .sort((menu1, menu2) => {
          // self-web と同じ順序でソート (category の priority -> categoryMenu の priority という優先度)
          // 複数カテゴリを持つものは，最も高い優先度で計算する
          const categoryPriority1 = Math.min(
            ...menu1.categoryMenus.map((categoryMenu) => categoryMenu.category.priority),
          );
          const categoryPriority2 = Math.min(
            ...menu2.categoryMenus.map((categoryMenu) => categoryMenu.category.priority),
          );
          if (categoryPriority1 !== categoryPriority2) return categoryPriority1 - categoryPriority2;

          const categoryMenuPriority1 = menu1.categoryMenus.find(
            (categoryMenu) => categoryMenu.category.priority === categoryPriority1,
          )?.priority;
          const categoryMenuPriority2 = menu2.categoryMenus.find(
            (categoryMenu) => categoryMenu.category.priority === categoryPriority2,
          )?.priority;
          if (categoryMenuPriority1 === undefined || categoryMenuPriority2 === undefined) {
            return categoryPriority1 - categoryPriority2;
          }

          return categoryMenuPriority1 - categoryMenuPriority2;
        }),
    [shopMenus],
  );

  const { data: getCategoriesData } = useShopMenusGetCategoriesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );
  const categories = getCategoriesData?.category ?? [];
  const menuCategoryIds = menus
    .map((menu) => menu.categoryMenus)
    .flatMap((categoryMenus) => categoryMenus.map((category) => category.categoryId));
  const filteredCategories = categories.filter((category) =>
    menuCategoryIds.includes(category.categoryId),
  );

  const { data: getRolesData, error: getRolesDataError } = useShopMenusGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles ?? [];

  const { data: getDishUpSlipGroupsData, error: getDishUpSlipGroupsDataError } =
    useShopMenusGetDishUpSlipGroupsQuery(shopId ? { variables: { shopId } } : { skip: true });
  const dishUpSlipGroups = getDishUpSlipGroupsData?.shop_by_pk?.dishUpSlipGroups ?? [];

  const { data: getKdDisplayTargetsData, error: getKdDisplayTargetsDataError } =
    useShopMenusGetKdDisplayTargetsQuery(shopId ? { variables: { shopId } } : { skip: true });
  const kdDisplayTargets = getKdDisplayTargetsData?.shop_by_pk?.kdDisplayTargets ?? [];

  const [updateShopMenuVisibleForCustomerMutation] =
    useShopMenusUpdateShopMenuVisibleForCustomerMutation();
  const [updateShopMenuVisibleForKioskMutation] =
    useShopMenusUpdateShopMenuVisibleForKioskMutation();
  const [updateShopMenuVisibleForStaffMutation] =
    useShopMenusUpdateShopMenuVisibleForStaffMutation();
  const [updateShopMenuStockMutation] = useShopMenusUpdateShopMenuStockMutation();
  const [updateShopMenusMutation, { loading: loadingUpdateShopMenus }] =
    useShopMenusUpdateShopMenusMutation();
  const [upsertShopChoicesMutation] = useShopMenusUpsertShopChoicesMutation();

  const updateIsVisibleForCustomer = useCallback(
    async ({ menuId, isVisibleForCustomer }: { menuId: string; isVisibleForCustomer: boolean }) => {
      if (!shopId) return;
      try {
        await updateShopMenuVisibleForCustomerMutation({
          variables: { shopId, menuId, isVisibleForCustomer },
        });
        await refetchShopMenus();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, updateShopMenuVisibleForCustomerMutation, refetchShopMenus],
  );

  const updateIsVisibleForKiosk = useCallback(
    async ({ menuId, isVisibleForKiosk }: { menuId: string; isVisibleForKiosk: boolean }) => {
      if (!shopId) return;
      try {
        await updateShopMenuVisibleForKioskMutation({
          variables: { shopId, menuId, isVisibleForKiosk },
        });
        await refetchShopMenus();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, updateShopMenuVisibleForKioskMutation, refetchShopMenus],
  );

  const updateIsVisibleForStaff = useCallback(
    async ({ menuId, isVisibleForStaff }: { menuId: string; isVisibleForStaff: boolean }) => {
      if (!shopId) return;
      try {
        await updateShopMenuVisibleForStaffMutation({
          variables: { shopId, menuId, isVisibleForStaff },
        });
        await refetchShopMenus();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, updateShopMenuVisibleForStaffMutation, refetchShopMenus],
  );

  const updateIsSoldOut = useCallback(
    async ({ menuId, isSoldOut }: { menuId: string; isSoldOut: boolean }) => {
      if (!shopId) return;
      const shopMenu = shopMenus.find(
        (shopMenu) => shopMenu.shopId === shopId && shopMenu.id === menuId,
      );
      const stockId = shopMenu?.stock?.id ?? uuidv4();
      try {
        await updateShopMenuStockMutation({
          variables: {
            shopId,
            menuId,
            stockId,
            stock: { id: stockId, shopId, currentStockNum: isSoldOut ? null : 0 },
          },
        });
        await refetchShopMenus();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, shopMenus, updateShopMenuStockMutation, refetchShopMenus],
  );

  const updateShopMenus = useCallback(
    async ({ menus, choices }: Pick<UpdateShopMenusInput, "menus" | "choices">) => {
      if (!shopId) return;

      try {
        await updateShopMenusMutation({
          variables: {
            input: {
              shopId,
              menus,
              choices,
            },
          },
        });
        await refetchShopMenus();
        message.success("編集を保存しました");
      } catch (err) {
        message.error("編集の保存に失敗しました");
      }
    },
    [shopId, updateShopMenusMutation, refetchShopMenus],
  );

  const upsertShopChoice = useCallback(
    async ({
      choiceId,
      isVisibleForCustomer,
      isVisibleForKiosk,
      isVisibleForStaff,
      currentStockNum,
    }: Pick<
      UpsertShopChoicesInput,
      | "choiceId"
      | "isVisibleForCustomer"
      | "isVisibleForKiosk"
      | "isVisibleForStaff"
      | "currentStockNum"
    >) => {
      if (!shopId) return;

      try {
        await upsertShopChoicesMutation({
          variables: {
            input: {
              shopIds: [shopId],
              choiceId,
              isVisibleForCustomer,
              isVisibleForKiosk,
              isVisibleForStaff,
              currentStockNum,
            },
          },
        });
        await refetchShopMenus();
        message.success("編集を保存しました");
      } catch {
        message.error("編集の保存に失敗しました");
      }
    },
    [upsertShopChoicesMutation, shopId, refetchShopMenus],
  );

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredMenus = useMemo(
    () => filterMenus(menus, filterConditions),
    [menus, filterConditions],
  );

  const shouldShowAlert =
    getMenusDataError ||
    getRolesDataError ||
    getDishUpSlipGroupsDataError ||
    getKdDisplayTargetsDataError;

  useEffect(
    () => setPagination({ current: 1 }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterConditions],
  );

  return (
    <DashboardLayout title="取扱メニュー一覧">
      <PageHeader
        title="取扱メニュー一覧"
        extra={[
          isFeatureEnabled("addMenu") && (
            <Link key="add" to="/menu/add">
              <Button type="primary">新規作成</Button>
            </Link>
          ),
        ]}
        footer={
          <>
            <Row gutter={16}>
              <Col>
                <ShopSelector />
              </Col>
              {isFeatureEnabled("editShopMenusBulk") && (
                <Col>
                  <Link to="/shopMenu/edit/bulk">
                    <StyledButton>複数店舗の取扱一括設定</StyledButton>
                  </Link>
                </Col>
              )}
            </Row>
            <ShopMenuFilter
              categories={filteredCategories}
              roles={roles}
              dishUpSlipGroups={dishUpSlipGroups}
              kdDisplayTargets={kdDisplayTargets}
              hasFilterConditions={hasFilterConditions}
              filterConditions={filterConditions}
              updateFilterCondition={updateFilterCondition}
              clearFilterConditions={clearFilterConditions}
              shouldShowKdDisplayTargetFilter={shop?.useKd ?? false}
            />
          </>
        }
      />
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <ShopMenuTable
        shopId={shopId}
        shouldShowKdDisplayTargetColumn={shop?.useKd ?? false}
        isEnabledEditShopMenu={isFeatureEnabled("editShopMenu")}
        menus={filteredMenus}
        roles={roles}
        kdDisplayTargets={kdDisplayTargets}
        dishUpSlipGroups={dishUpSlipGroups}
        loading={loadingMenus || loadingUpdateShopMenus}
        onUpdateIsVisibleForCustomer={updateIsVisibleForCustomer}
        onUpdateIsVisibleForKiosk={updateIsVisibleForKiosk}
        onUpdateIsVisibleForStaff={updateIsVisibleForStaff}
        onUpdateIsSoldOut={updateIsSoldOut}
        onUpdateShopMenus={updateShopMenus}
        onUpsertShopChoice={upsertShopChoice}
        defaultPagination={defaultPagination}
        setPagination={setPagination}
      />
    </DashboardLayout>
  );
};
