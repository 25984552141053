import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Checkbox, Switch, Tag } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import type { TableRowSelection } from "antd/lib/table/interface";
import { isSoldOut } from "models/stock";
import { uniq } from "util/array";
import { isNotNull, isNotNullable } from "util/type/primitive";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { FormHelp } from "components/Form/FormHelp";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { Thumbnail } from "components/Thumbnail";
import { useCanAccess } from "hooks/useCanAccess";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import type { DishUpSlipGroup, KdDisplayTarget, Menu, Role } from "pages/ShopMenus/types";
import type {
  UpdateShopMenusInput,
  UpdateShopMenusInputMenu,
  UpsertShopChoicesInput,
} from "types/graphql";

import { BulkUpdateBanner } from "./BulkUpdateBanner";
import { isBulkEditConditionValueChanged } from "./functions";
import type { BulkEditConditions, RowItem, RowItemChoice, RowItemMenu } from "./types";
import { useApplyBulkEditConditionsToChoices } from "./useApplyBulkEditConditionsToChoices";
import { useApplyBulkEditConditionsToMenus } from "./useApplyBulkEditConditionsToMenus";
import { useChoices } from "./useChoices";
import { useSelectedRowKeys } from "./useSelectedRowKeys";

const DEFAULT_PAGE_SIZE = 10;

const isRowItemMenu = (
  rowItem: RowItem & { rowKey: string },
): rowItem is RowItemMenu & { rowKey: string } => rowItem.type === "menu";
const isRowItemChoice = (
  rowItem: RowItem & { rowKey: string },
): rowItem is RowItemChoice & { rowKey: string } => rowItem.type === "choice";

const Wrapper = styled.div`
  /* NOTE: styled(Table) だとtsエラー */
  .ant-table-selection-column {
    text-align: right;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AllCheckboxWrapper = styled.div`
  white-space: nowrap;
`;

const AllCheckbox = styled(Checkbox)`
  margin-left: 8px;
`;

type Props = {
  loading: boolean;
  shopId?: string;
  shouldShowKdDisplayTargetColumn: boolean;
  isEnabledEditShopMenu: boolean;
  menus: Menu[];
  roles: Role[];
  kdDisplayTargets: KdDisplayTarget[];
  dishUpSlipGroups: DishUpSlipGroup[];
  onUpdateIsVisibleForCustomer: ({
    menuId,
    isVisibleForCustomer,
  }: {
    menuId: string;
    isVisibleForCustomer: boolean;
  }) => void;
  onUpdateIsVisibleForKiosk: ({
    menuId,
    isVisibleForKiosk,
  }: {
    menuId: string;
    isVisibleForKiosk: boolean;
  }) => void;
  onUpdateIsVisibleForStaff: ({
    menuId,
    isVisibleForStaff,
  }: {
    menuId: string;
    isVisibleForStaff: boolean;
  }) => void;
  onUpdateIsSoldOut: ({ menuId, isSoldOut }: { menuId: string; isSoldOut: boolean }) => void;
  onUpdateShopMenus: ({
    menus,
    choices,
  }: Pick<UpdateShopMenusInput, "menus" | "choices">) => Promise<void>;
  onUpsertShopChoice: ({
    choiceId,
    isVisibleForCustomer,
    isVisibleForStaff,
    currentStockNum,
  }: Pick<
    UpsertShopChoicesInput,
    | "choiceId"
    | "isVisibleForCustomer"
    | "isVisibleForKiosk"
    | "isVisibleForStaff"
    | "currentStockNum"
  >) => Promise<void>;
  defaultPagination: {
    current: number;
    defaultPageSize: number;
    defaultCurrent: number;
    showSizeChanger: boolean;
  };
  setPagination: ({ pageSize, current }: PaginationConfig) => void;
};

export const ShopMenuTable = memo<Props>(
  ({
    loading,
    shopId,
    shouldShowKdDisplayTargetColumn,
    isEnabledEditShopMenu,
    menus,
    roles,
    dishUpSlipGroups,
    kdDisplayTargets,
    onUpdateIsVisibleForCustomer,
    onUpdateIsVisibleForKiosk,
    onUpdateIsVisibleForStaff,
    onUpdateIsSoldOut,
    onUpdateShopMenus,
    onUpsertShopChoice,
    defaultPagination,
    setPagination,
  }) => {
    const pagination = useMemo(
      () => ({
        ...defaultPagination,
        defaultCurrent: defaultPagination.defaultCurrent ?? 1,
        defaultPageSize: defaultPagination.defaultPageSize ?? DEFAULT_PAGE_SIZE,
      }),
      [defaultPagination],
    );

    const currentPageMenuIds = useMemo(() => {
      const startIndex = (pagination.defaultCurrent - 1) * pagination.defaultPageSize;
      const endIndex = pagination.defaultCurrent * pagination.defaultPageSize;

      return menus.slice(startIndex, endIndex).map((menu) => menu.menuId);
    }, [menus, pagination.defaultCurrent, pagination.defaultPageSize]);

    const {
      selectedRowKeys: selectedMenuIds,
      setSelectedRowKeys: setSelectedMenuIds,
      currentPageSelectedRowKeys: currentPageSelectedMenuIds,
      clearCurrentPageSelectedRowKeys: clearCurrentPageSelectedMenuIds,
    } = useSelectedRowKeys({ currentPageRowKeys: currentPageMenuIds });

    const { currentPageChoiceRowKeys, choices, displayedChoices, setDisplayedChoices } = useChoices(
      {
        menus,
        currentPageMenuIds,
      },
    );

    const {
      selectedRowKeys: selectedChoiceRowKeys,
      setSelectedRowKeys: setSelectedChoiceRowKeys,
      currentPageSelectedRowKeys: currentPageSelectedChoiceRowKeys,
      clearCurrentPageSelectedRowKeys: clearCurrentPageSelectedChoiceRowKeys,
    } = useSelectedRowKeys({ currentPageRowKeys: currentPageChoiceRowKeys });

    const currentPageSelectedRowKeys = useMemo(
      () => [...currentPageSelectedMenuIds, ...currentPageSelectedChoiceRowKeys],
      [currentPageSelectedMenuIds, currentPageSelectedChoiceRowKeys],
    );

    const selectedRowKeys = useMemo(
      () => [...selectedMenuIds, ...selectedChoiceRowKeys].map(String),
      [selectedMenuIds, selectedChoiceRowKeys],
    );

    const isSelectedAllRows =
      menus.length !== 0 && currentPageSelectedMenuIds.length === currentPageMenuIds.length;

    const onAllCheckboxClick = useCallback(() => {
      if (isSelectedAllRows) {
        clearCurrentPageSelectedMenuIds();
        clearCurrentPageSelectedChoiceRowKeys();
        return;
      }

      setSelectedMenuIds(uniq([...selectedMenuIds, ...currentPageMenuIds]));
    }, [
      isSelectedAllRows,
      clearCurrentPageSelectedMenuIds,
      clearCurrentPageSelectedChoiceRowKeys,
      setSelectedMenuIds,
      selectedMenuIds,
      currentPageMenuIds,
    ]);

    const allCheckboxProps = useMemo(
      () => ({
        indeterminate: currentPageSelectedRowKeys.length !== 0 && !isSelectedAllRows,
        checked: isSelectedAllRows,
        onClick: onAllCheckboxClick,
      }),
      [currentPageSelectedRowKeys, isSelectedAllRows, onAllCheckboxClick],
    );

    const [bulkEditConditions, setBulkEditConditions] = useState<BulkEditConditions>({});

    const updateBulkEditCondition = <K extends keyof BulkEditConditions>(
      key: K,
      value: BulkEditConditions[K],
    ) => {
      setBulkEditConditions({
        ...bulkEditConditions,
        [key]: value,
      });
    };

    const clearBulkEditConditions = () => {
      clearCurrentPageSelectedMenuIds();
      clearCurrentPageSelectedChoiceRowKeys();
      setBulkEditConditions({});
    };

    const [displayedMenus, setDisplayedMenus] = useState(menus);

    const getUpdateValue = useCallback(
      <T extends UpdateShopMenusInputMenu[keyof UpdateShopMenusInputMenu]>(
        key: keyof BulkEditConditions,
        value: T,
      ): T | undefined =>
        isBulkEditConditionValueChanged(bulkEditConditions[key]) ? value : undefined,
      [bulkEditConditions],
    );

    useApplyBulkEditConditionsToMenus({
      menus,
      bulkEditConditions,
      currentPageSelectedMenuIds,
      roles,
      dishUpSlipGroups,
      kdDisplayTargets,
      setDisplayedMenus,
    });

    useApplyBulkEditConditionsToChoices({
      choices,
      bulkEditConditions,
      currentPageSelectedChoiceRowKeys,
      setDisplayedChoices,
    });

    const onUpdateButtonClick = async () => {
      // NOTE: 一括変更対象のフィールド以外は undefined に
      // currentStockNum, dailyStockNum は一方のみを送信するともう一方は null で更新されてしまうため常に両方を送信
      const updateMenus = displayedMenus
        .filter((menu) => currentPageSelectedMenuIds.includes(menu.menuId))
        .map((menu) => ({
          menuId: menu.id,
          _menuId: menu.menuId,
          isVisibleForCustomer: menu.isVisibleForCustomer,
          isVisibleForStaff: menu.isVisibleForStaff,
          currentStockNum: menu.stock?.currentStockNum,
          dailyStockNum: menu.stock?.dailyStockNum,
          dishUpSlipGroupIds: getUpdateValue(
            "dishUpSlipGroupIds",
            menu.dishUpSlipGroupShopMenus
              .map(({ dishUpSlipGroup }) => dishUpSlipGroup?.id)
              .filter(isNotNullable),
          ),
          kitchenRoleIds: getUpdateValue(
            "kitchenRoleIds",
            menu.shopMenuKitchenRoles.map(({ role }) => role?.roleId).filter(isNotNullable),
          ),
          kdDisplayTargetIds: getUpdateValue(
            "kdDisplayTargetIds",
            menu.shopMenuKdDisplayTargets
              .map(({ kdDisplayTarget }) => kdDisplayTarget?.id)
              .filter(isNotNullable),
          ),
        }));

      const updateChoices = displayedChoices
        .filter((choice) =>
          currentPageSelectedChoiceRowKeys.includes(`${choice.menuId}-${choice.choiceId}`),
        )
        .map((choice) => ({
          choiceId: choice.id,
          _choiceId: choice.choiceId,
          isVisibleForCustomer: choice.shopChoices[0]?.isVisibleForCustomer,
          isVisibleForStaff: choice.shopChoices[0]?.isVisibleForStaff,
          currentStockNum: getUpdateValue(
            "isSoldOut",
            choice.shopChoices[0]?.stock?.currentStockNum,
          ),
        }));

      await onUpdateShopMenus({ menus: updateMenus, choices: updateChoices });
      clearBulkEditConditions();
    };

    const choiceIdToDisplayedChoiceMap = useMemo(
      () => new Map<string, RowItemChoice>(displayedChoices.map((choice) => [choice.id, choice])),
      [displayedChoices],
    );

    const rows = useMemo<(RowItem & { rowKey: string })[]>(
      () =>
        displayedMenus.map((menu) => ({
          ...menu,
          type: "menu",
          rowKey: menu.menuId.toString(),
          children:
            menu.menuOptions.length > 0
              ? menu.menuOptions.flatMap(({ option }) =>
                  option.choices.map((choice) => ({
                    ...choice,
                    shopChoices: choiceIdToDisplayedChoiceMap.get(choice.id)?.shopChoices ?? [],
                    type: "choice" as const,
                    optionId: option.optionId,
                    menuId: menu.menuId,
                    name: `${option.name} ${choice.name}`,
                    rowKey: `${menu.menuId.toString()}-${choice.choiceId.toString()}`,
                  })),
                )
              : null,
        })),
      [choiceIdToDisplayedChoiceMap, displayedMenus],
    );

    const { canAccess } = useCanAccess();

    const { isFeatureEnabled } = useIsFeatureEnabled();

    const columns = [
      {
        title: "",
        width: 40,
      } as const,
      {
        title: "画像",
        align: "center",
        width: 80,
        render(_: string, rowItem: RowItem) {
          if (rowItem.type !== "menu") return null;
          return <Thumbnail url={rowItem.imageUrl} width={64} height={64} />;
        },
      } as const,

      {
        title: "メニュー名",
        width: 120,
        render(_: string, rowItem: RowItem) {
          if (shopId === undefined) return null;

          switch (rowItem.type) {
            case "choice": {
              const { optionId, name } = rowItem;
              return <Link to={`/option/${optionId}/edit`}>{name}</Link>;
            }
            case "menu": {
              const { menuId, name } = rowItem;
              return <Link to={`/menu/${menuId}/edit`}>{name}</Link>;
            }
            default:
              return null;
          }
        },
      },
      {
        title: "カテゴリ名",
        render(_: string, rowItem: RowItem) {
          if (rowItem.type !== "menu") return null;

          const { categoryMenus } = rowItem;
          return categoryMenus.map(({ categoryId, category: { name } }) => (
            <Tag key={categoryId}>{name}</Tag>
          ));
        },
      },
      {
        title: "公開設定",
        children: [
          {
            title() {
              return <FormHelp label="お客様" help="スマートフォン上での設定" />;
            },
            align: "center",
            width: 120,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "choice": {
                  const { menuId, id: choiceId, choiceId: _choiceId, shopChoices } = rowItem;
                  const shopChoice = shopChoices[0];
                  const isVisibleForCustomer = shopChoice?.isVisibleForCustomer ?? true;
                  const isRowChecked = currentPageSelectedChoiceRowKeys.includes(
                    `${menuId}-${_choiceId}`,
                  );

                  return (
                    <Switch
                      checked={isVisibleForCustomer}
                      onChange={(value) =>
                        onUpsertShopChoice({
                          choiceId,
                          isVisibleForCustomer: value,
                        })
                      }
                      disabled={isRowChecked || !isFeatureEnabled("editShopMenuDisplaySettings")}
                    />
                  );
                }
                case "menu": {
                  const { id: menuId, menuId: _menuId, isVisibleForCustomer } = rowItem;
                  const isRowChecked = currentPageSelectedMenuIds.includes(_menuId);

                  return (
                    <Switch
                      checked={isVisibleForCustomer}
                      onChange={(isVisibleForCustomer) =>
                        onUpdateIsVisibleForCustomer({ menuId, isVisibleForCustomer })
                      }
                      disabled={isRowChecked || !isFeatureEnabled("editShopMenuDisplaySettings")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
          {
            title() {
              return <FormHelp label="キオスク" help="キオスク上での設定" />;
            },
            align: "center",
            width: 120,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "choice": {
                  const { menuId, id: choiceId, choiceId: _choiceId, shopChoices } = rowItem;
                  const shopChoice = shopChoices[0];
                  const isVisibleForKiosk = shopChoice?.isVisibleForKiosk ?? true;
                  const isRowChecked = currentPageSelectedChoiceRowKeys.includes(
                    `${menuId}-${_choiceId}`,
                  );

                  return (
                    <Switch
                      checked={isVisibleForKiosk}
                      onChange={(value) =>
                        onUpsertShopChoice({
                          choiceId,
                          isVisibleForKiosk: value,
                        })
                      }
                      disabled={isRowChecked || !isFeatureEnabled("editShopMenuDisplaySettings")}
                    />
                  );
                }
                case "menu": {
                  const { id: menuId, menuId: _menuId, isVisibleForKiosk } = rowItem;
                  const isRowChecked = currentPageSelectedMenuIds.includes(_menuId);

                  return (
                    <Switch
                      checked={isVisibleForKiosk}
                      onChange={(isVisibleForKiosk) =>
                        onUpdateIsVisibleForKiosk({ menuId, isVisibleForKiosk })
                      }
                      disabled={isRowChecked || !isFeatureEnabled("editShopMenuDisplaySettings")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
          {
            title() {
              return <FormHelp label="スタッフ" help="ハンディ上での表示設定" />;
            },
            align: "center",
            width: 120,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "choice": {
                  const { menuId, id: choiceId, choiceId: _choiceId, shopChoices } = rowItem;
                  const shopChoice = shopChoices[0];
                  const isVisibleForStaff = shopChoice?.isVisibleForStaff ?? true;
                  const isRowChecked = currentPageSelectedChoiceRowKeys.includes(
                    `${menuId}-${_choiceId}`,
                  );

                  return (
                    <Switch
                      checked={isVisibleForStaff}
                      onChange={(value) =>
                        onUpsertShopChoice({
                          choiceId,
                          isVisibleForStaff: value,
                        })
                      }
                      disabled={isRowChecked || !isFeatureEnabled("editShopMenuDisplaySettings")}
                    />
                  );
                }
                case "menu": {
                  const { id: menuId, menuId: _menuId, isVisibleForStaff } = rowItem;
                  const isRowChecked = currentPageSelectedMenuIds.includes(_menuId);

                  return (
                    <Switch
                      checked={isVisibleForStaff}
                      onChange={(value) =>
                        onUpdateIsVisibleForStaff({ menuId, isVisibleForStaff: value })
                      }
                      disabled={isRowChecked || !isFeatureEnabled("editShopMenuDisplaySettings")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
        ],
      },
      {
        title: "在庫設定",
        children: [
          {
            title: "在庫",
            align: "center",
            width: 100,
            render(_: string, rowItem: RowItem) {
              switch (rowItem.type) {
                case "choice": {
                  const { menuId, id: choiceId, choiceId: _choiceId, shopChoices } = rowItem;
                  const shopChoice = shopChoices[0];
                  const stock = shopChoice?.stock;
                  const isRowChecked = currentPageSelectedChoiceRowKeys.includes(
                    `${menuId}-${_choiceId}`,
                  );

                  return (
                    <Switch
                      checked={!isSoldOut({ stock })}
                      onChange={(value) =>
                        onUpsertShopChoice({
                          choiceId,
                          currentStockNum: value ? null : 0,
                        })
                      }
                      disabled={isRowChecked || !canAccess("editShopMenuStock")}
                    />
                  );
                }
                case "menu": {
                  const { id: menuId, menuId: _menuId, stock } = rowItem;
                  const isRowChecked = currentPageSelectedMenuIds.includes(_menuId);

                  return (
                    <Switch
                      checked={!isSoldOut({ stock })}
                      onChange={(isSoldOut) => onUpdateIsSoldOut({ menuId, isSoldOut })}
                      disabled={isRowChecked || !canAccess("editShopMenuStock")}
                    />
                  );
                }
                default:
                  return null;
              }
            },
          } as const,
          {
            title: "在庫数",
            align: "center",
            width: 100,
            render(_: string, rowItem: RowItem) {
              if (rowItem.type !== "menu") return null;
              const currentStockNum = rowItem.stock?.currentStockNum;
              return typeof currentStockNum === "number" ? `${currentStockNum} 個` : "無制限";
            },
          } as const,
          {
            title: "日次在庫数",
            align: "center",
            width: 120,
            render(_: string, rowItem: RowItem) {
              if (rowItem.type !== "menu") return null;
              const dailyStockNum = rowItem.stock?.dailyStockNum;
              return typeof dailyStockNum === "number" ? `${dailyStockNum} 個` : "未設定";
            },
          } as const,
          ...(canAccess("editShopMenuStock")
            ? [
                {
                  title: "",
                  align: "center",
                  width: 60,
                  render(_: string, rowItem: RowItem) {
                    if (shopId === undefined) return null;
                    if (rowItem.type !== "menu") return null;
                    return (
                      <IconLink to={`/shop/${shopId}/menu/${rowItem.menuId}/stock/edit`}>
                        <EditIcon />
                      </IconLink>
                    );
                  },
                } as const,
              ]
            : []),
        ],
      },
      {
        title: "出力先設定",
        children: [
          {
            title: "キッチンプリンター",
            width: 160,
            render(_: unknown, rowItem: RowItem) {
              if (rowItem.type !== "menu") return null;
              return rowItem.shopMenuKitchenRoles.map(({ role }) => (
                <Tag key={role?.roleId}>{role?.name}</Tag>
              ));
            },
          },
          {
            title: "デシャップグループ",
            width: 160,
            render(_: number, rowItem: RowItem) {
              if (rowItem.type !== "menu") return null;
              return rowItem.dishUpSlipGroupShopMenus.map(({ dishUpSlipGroup }) => (
                <Tag key={dishUpSlipGroup?.id}>{dishUpSlipGroup?.name}</Tag>
              ));
            },
          } as const,
          shouldShowKdDisplayTargetColumn
            ? {
                title: "キッチンディスプレイ",
                width: 180,
                render(_: string, rowItem: RowItem) {
                  if (rowItem.type !== "menu") return null;
                  return rowItem.shopMenuKdDisplayTargets.map(({ kdDisplayTarget }) => (
                    <Tag color="blue" key={kdDisplayTarget?.id}>
                      {kdDisplayTarget?.name}
                    </Tag>
                  ));
                },
              }
            : null,
          canAccess("editShopMenuOutput")
            ? ({
                title: "",
                align: "center",
                width: 60,
                render(_: string, rowItem: RowItem) {
                  if (shopId === undefined) return null;
                  if (rowItem.type !== "menu") return null;
                  return (
                    <IconLink to={`/shop/${shopId}/menu/${rowItem.menuId}/output/edit`}>
                      <EditIcon />
                    </IconLink>
                  );
                },
              } as const)
            : null,
        ].filter(isNotNull),
      } as const,
    ];

    const rowSelection: TableRowSelection<RowItem & { rowKey: string }> = useMemo(
      () => ({
        columnTitle: (
          <AllCheckboxWrapper>
            全選択
            <AllCheckbox {...allCheckboxProps} />
          </AllCheckboxWrapper>
        ),
        selectedRowKeys,
        onChange: (_, rows) => {
          setSelectedMenuIds(rows.filter(isRowItemMenu).map(({ menuId }) => menuId));
          setSelectedChoiceRowKeys(
            rows.filter(isRowItemChoice).map(({ menuId, choiceId }) => `${menuId}-${choiceId}`),
          );
        },
        columnWidth: 80,
      }),
      [allCheckboxProps, selectedRowKeys, setSelectedMenuIds, setSelectedChoiceRowKeys],
    );

    return (
      <Wrapper data-cy="shop-menu-table">
        {currentPageSelectedRowKeys.length !== 0 && (
          <BulkUpdateBanner
            onClearButtonClick={clearBulkEditConditions}
            selectedCount={currentPageSelectedRowKeys.length}
            roles={roles}
            kdDisplayTargets={kdDisplayTargets}
            dishUpSlipGroups={dishUpSlipGroups}
            bulkEditConditions={bulkEditConditions}
            updateBulkEditCondition={updateBulkEditCondition}
            onUpdateButtonClick={onUpdateButtonClick}
            loading={loading}
            shouldShowKdDisplayTargetField={shouldShowKdDisplayTargetColumn}
          />
        )}
        <Table<RowItem & { rowKey: string }>
          rowKey={({ rowKey }) => rowKey}
          columns={columns}
          dataSource={rows}
          loading={loading}
          bordered
          pagination={pagination}
          onChange={({ position: _, ...pagination }) => setPagination(pagination)}
          rowSelection={isEnabledEditShopMenu ? rowSelection : undefined}
        />
      </Wrapper>
    );
  },
);
