import dayjs from "dayjs";

const gcsBucketToImgixSourceMap = [
  {
    gcs: "://storage.googleapis.com/dinii-self-assets/",
    imgix: "://dinii-self.imgix.net/",
  },
  {
    gcs: "://storage.googleapis.com/dinii-self-prod-assets/",
    imgix: "://dinii-self-prod.imgix.net/",
  },
  {
    gcs: "://storage.googleapis.com/dinii-self-stag-assets/",
    imgix: "://dinii-self-stag.imgix.net/",
  },
  {
    gcs: "://storage.googleapis.com/dinii-self-develop-assets/",
    imgix: "://dinii-self-develop.imgix.net/",
  },
  {
    gcs: "://storage.googleapis.com/dinii-self-beta-assets/",
    imgix: "://dinii-self-beta.imgix.net/",
  },
  {
    gcs: "://storage.googleapis.com/dinii-discovery-assets/",
    imgix: "://dinii-discovery.imgix.net/",
  },
  {
    gcs: "://storage.googleapis.com/dinii-discovery-stag-assets/",
    imgix: "://dinii-discovery-stag.imgix.net/",
  },
];

const generateImgixUrl = (
  url: string | null = null,
  w: number,
  h: number,
  fit: "crop" | "clip",
  frame?: number,
) => {
  if (!url) return null;

  // NOTE: 1.124.x 以前にアップロードされた URL は "%n" を含む unsafe な URL になっており、imgix でエラーになった問題があったので encoding する
  // (https://dinii.slack.com/archives/CUHEB9SAJ/p1693630775499499?thread_ts=1693287041.757859&cid=CUHEB9SAJ)
  const result = new URL(encodeURI(url.trim()));

  result.searchParams.set("fit", fit);
  result.searchParams.set("w", String(w));
  result.searchParams.set("h", String(h));
  result.searchParams.set("auto", "compress");
  if (frame !== undefined) result.searchParams.set("frame", String(frame));

  // NOTE: 1.124.x 以前にアップロードされた URL は node:utils#format の使い方に誤りがあり
  // `%n-{timestamp}{uuid} .{extension}` というような形式のファイル名になっていた。
  // 1.124.x からはそれが修正されており、 `{timestamp}-{uuid}.{extension}` という形式になっている。
  // なお、このコードは下記の移行期間が過ぎたあとには不要になる。
  const uploadTimestamp = result.pathname.match(
    /\d{10}(?=-?[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/,
  )?.[0];
  const uploadedAt = uploadTimestamp ? Number(`${uploadTimestamp}000`) : Date.now();

  // NOTE: いきなりすべての画像に対してカラープロファイルを適用するようにしてしまうと、元の色の見え方に応じて色の調整をした上で
  // アップロードしている画像の見え方が大きく変わってしまう。そのため 2023-11-01 までを移行期間とし、それまでは 2023-08-28
  // 以降にアップロードされた画像のみをカラープロファイル設定の対象とする。
  if (
    dayjs().isBefore(dayjs("2023-10-31 15:00")) &&
    dayjs(uploadedAt).isAfter(dayjs("2023-08-27 15:00"))
  ) {
    // NOTE: 色にこだわって画像設定をする店舗の要望に応えるため、色の表現範囲が広い adobe1998 を採用している。
    // これにより、元画像が sRGB や他のカラープロファイルの画像を使用している場合には彩度が強調される場合がある。
    // https://dinii.slack.com/archives/C0333NCAAVC/p1691996417480669
    result.searchParams.set("cs", "adobergb1998");
  }

  return gcsBucketToImgixSourceMap.reduce(
    (prev, { gcs, imgix }) => prev.replace(gcs, imgix),
    result.toString(),
  );
};

type GetUrlParams = {
  url?: string | null;
  w: number;
  h: number;
  frame?: number;
};

export const getCroppedUrl = ({ url, w, h, frame }: GetUrlParams) =>
  url ? generateImgixUrl(url, w, h, "crop", frame) : null;

export const getClippedUrl = ({ url, w, h, frame }: GetUrlParams) =>
  url ? generateImgixUrl(url, w, h, "clip", frame) : null;
